html,
body {
  scroll-behavior: smooth !important;
  width: 100%;
}

* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  scroll-behavior: smooth !important;
  /* outline: 2px solid red; */
}

.mask1 {
  mask-image: linear-gradient(to left, transparent -2%, rgb(0, 0, 0));
  -webkit-mask-image: linear-gradient(to left, transparent -2%, rgb(0, 0, 0));
}

img.tint {
  filter: brightness(0.7);
}

.animate-in {
  transition: 0.5s;
  opacity: 1;
}

.animate-mid-out {
  transition: 0.5s;
  opacity: 0.5;
}

.animate-out {
  transition: 0.5s;
  opacity: 0;
}

.hideMenu {
  position: fixed;
  left: 0;
  top: 0;
  transform: translate3d(-100vw, 0, 0);

  width: 100vw;
  height: 100vh;
  transition: 0.5s;
}

.showMenu {
  position: fixed;
  left: 0;
  top: 0;
  transform: translate3d(0vw, 0, 0);

  width: 100vw;
  height: 100vh;
  transition: 0.5s;
}

.is-sticky {
  position: fixed;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

/* scrollbar */

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #9292924d;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.in {
  animation: append-animate 0.3s linear;
}

.out {
  opacity: 0;
  transition: opacity 1s;
}

.synthetic_hover_img_card {
  transition: 0.2s ease-in-out;
  transform: rotate(1deg) scale(0.8);
  border-radius: 50px;
}

@keyframes append-animate {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
